html,
body {
    height: -webkit-fill-available;
    margin: 0;
    padding: 0;
}

#root::after {
    /* background-image: url(../images/bg.png); */
    background-color: #08041c;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: top;
}

/* generales */
.padre {
    display: table;

}
.padre .hijos {
    display: table-cell;
    vertical-align: middle;
}

.full {
    width: 100%;
}

.center {
    text-align: center
}
.contenedor-streaming {
    padding: 56.25% 0 0 0;
    position: relative;

}

.contenedor-streaming .estilo-streaming {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.contentenedor-chat {
    height: 100%;
}

.mt60 {
    margin-top: 60px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb30 {
    margin-bottom: 30px;
}

.cursor {
    cursor: pointer;
}

.padding {
    padding: 15px;
}

::-webkit-scrollbar {
    width: 0px;
}


@media screen and (max-width: 768px) and (orientation: portrait) {
}

@media screen and (max-width: 1024px) and (orientation: landscape) {

}