#home #section1 {
  padding: 50px 0;
}
#home #section2 {
  padding: 0 0 80px 0;
}

@media screen and (max-width: 1100px) and (orientation: portrait) {
  #home #section2 .contentenedor-chat {
    height: 400px;
  }
  
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
  #home #section2  .contentenedor-chat {
    height: 400px;
  }
  
}
